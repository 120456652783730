import Vue from "vue";
// lib模式下，vue的主入口文件
import metaVue from "./meta.vue";
import SpeechTranscription from "./utils/ali/st";
import Recorder from "./utils/ali/recorder";
import $ from "jquery";
import { alertText, randomString, replace } from "./utils/myUtils";

let hostDevelop = "https://metawa.cn";
let hostProduction = "https://www.metawa.cn";
let searchData = "";
let changeData = "";
let recorder = null;
let threeDWebGl;
let unityInstanceV = null;
let loading;
let metabtns;
let metaContainer;
let Metabtn;
let closeBtn;
let soundBtn;
let closeBtns;
let talkbox;
let voicebtn;
let metabtnsBox;
let sendTextBtn;
let sendTextInput;
let metatalkbox;
let content;
let baize_think = `<li class="baize_think" >
<img class="imgleft" style="width: 40px;height: 40px;border-radius: 50%" src="/baize/bz.png" alt="">
<div class="spanleft">正在思考中<span class="flicker_span"> _</span></div>
</li>`;
let txtId = 0;
let img;
let span;
let iNow = -1; //用来累加改变左右浮动
let metaname;
let audio = null;
let soundBgm = null;
let stWeb = null;
let MetaWaReady = false;
let loaded = 0;
let wav = true;
let outerId = randomString(4);
const outer = document.createElement("div");

outer.setAttribute("id", `Login${outerId}`);
document.body.appendChild(outer);

function debounce(func, wait = 1000) {
  let timer = null;
  return function (...args) {
    if (timer !== null) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
      timer = null;
    }, wait);
  };
}
// 构建实例

let aliTimer = null;

class virtual {
  constructor(flag, config) {
    let that = this;
    this.locaConfig = {
      sound: true,
      voice: false,
    };
    this.config = config ? config : {};
    this.host = this.config.env == "dev" ? hostDevelop : hostProduction;
    this.flag = flag;
    this.talking = false;
    this.option = null;
    this.argsdata = null;
    this.session_id = null;
    this.aliurl = "";
    this.appKey = "";
    this.state = "INIT"; //状态
    this.humanPos = null;
    this.cameraPos = null;
    this.welcome = null;
    this.noReturn = null;
    this.timeOut = null;
    this.duration = 1;
    this.ViewMode = "";
    this.publish = null;
    this.beforeclose = false;
    this.talkpos = {};
    this.reference = 0;
    this.reference_txt = "";
    this.say_text = "";
    this.wav = true;
    this.tabStatus = true;
    // this.sound =  localStorage.getItem('')
    // 2.vue挂载
    this.vue = new Vue({
      render: (h) => h(metaVue),
    }).$mount(`#Login${outerId}`);
    this.$vue = this.vue.$children[0];
    //that.locaConfig.voice = that.$vue.voice;
    metaContainer = document.getElementById("metaContainer");
    loading = document.getElementById(`loading`);
    metabtns = document.getElementById(`metabtns`);
    threeDWebGl = document.getElementById("scene3dContainerSdk");
    window.addEventListener("message", this.unityWatch.bind(this));
    Metabtn = document.getElementById(`MetabtnSdk`);
    //Metabtn = document.getElementById(`Metabtn${this.$vue.id}`);
    talkbox = document.getElementById(`talkboxSdk`);
    closeBtn = document.getElementById(`closeBtnSdk`);
    closeBtns = document.getElementById(`closeBtnsSdk`);
    voicebtn = document.getElementById(`voiceSdk`);
    metaname = document.getElementById(`metanameSdk`);
    metatalkbox = document.getElementById(
      //`metatalkbox${this.$vue.id}`
      `containerSdk`
    );
    soundBtn = document.getElementById(`soundSdk`);
    sendTextBtn = document.getElementById(`sendTextSdk`);
    sendTextInput = document.getElementById(`sendTextInputSdk`);
    content = document.getElementById("contentSdk");
    img = content.getElementsByTagName("img");
    span = content.getElementsByTagName("span");
    metabtnsBox = document.getElementById('metabtnsBox')
    if (this.config.show == "hide") {
      metabtnsBox.style.display = "none";
    }
    localStorage.getItem("BAIZE-SDK-CONFIG")
      ? this.getLocalConfig()
      : this.setConfig();
    that.#getConfig();
    this.watchView();
  }
  //外部开启隐藏整个sdk
  showSdk(show) {
    if (show) {
      metabtnsBox.style.display = "block";
    } else {
      metabtnsBox.style.display = "none";
    }
  }
  //监听页面活跃状态
  watchView() {
    let that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        that.tabStatus = true;
        that.talking = false;
        if (that.locaConfig.voice && MetaWaReady) {
          console.log("开始收音");
          that.#getalisession();
        }
      } else if (document.visibilityState === "hidden") {
        that.tabStatus = false;
        if (MetaWaReady) {
          that.#closeVoice();
          if (unityInstanceV) {
            unityInstanceV.SendMessage(
              "WebManager",
              "WebToUnity",
              JSON.stringify({
                OpCode: 1003, //事件类型,unity需要
              })
            );
          }
        }
        that.talking = false;
        that.$vue.userTalk = false;
      }
    });
  }
  loadOver() {
    metabtns.style.display = "flex";
    loading.style.display = "none";
  }
  unityWatch(e) {
    //监听unity回调
    if (e.data == "Unityinstancecreated") {
      let iframe = document.getElementById("iframeSdk");
      unityInstanceV = iframe.contentWindow.unityInstanceV;
      loaded++;
      if (loaded == 2) {
        this.loadOver();
      }
      this.callback({
        state: "SUCCESS",
        msg: "虚拟人资源加载成功",
      });
    }
    if (e.data.OpCode == 2000) {
      //回答完毕
      this.callback({
        state: "COMPLETE",
        msg: "播放完成",
      });
      if (this.locaConfig.voice) this.#getalisession();
      this.talking = false;
    }
    if (e.data.OpCode == 2001) {
      // console.log("音频播放开始", e.data);
      this.callback({
        state: "BEGIN",
        msg: "开始播放",
      });
    }
  }
  getLocalConfig() {
    this.locaConfig = JSON.parse(localStorage.getItem("BAIZE-SDK-CONFIG"));
    this.$vue.sound = this.locaConfig.sound;
    this.$vue.voice = this.locaConfig.voice;
  }
  setConfig() {
    localStorage.setItem("BAIZE-SDK-CONFIG", JSON.stringify(this.locaConfig));
  }
  //获取配置信息
  #getConfig() {
    let that = this;
    $.ajax({
      method: "get",
      url: that.host + "/api/wisdom/service/publish",
      data: {
        flag: that.flag,
      },
      success: function ({ data }) {
        that.publish = data;
        that.noReturn = data.noReturn;
        that.timeOut = data.timeOut;

        // $(metaname).text(data.anchor_name);
        $(metaname).text("白泽");
        that.callback({ state: "CONFIG", msg: "获取配置信息成功" });
        that.state = "CONFIG";
        that.cameraPos = data.cameraPos;
        that.humanPos = data.humanPos;
        for (let i in data.humanPos) {
          if (i != "Scale") {
            that.talkpos[i] = (1 - data.humanPos[i]) / 2 / 0.01;
          }
        }
        that.option = {
          hostUrl: data.serverAddress, //元娲云地址
          groupId: data.groupId, //群组id
          appliId: data.appliId, //应用id 3
          authCode: data.sdkAuthCode, // 鉴权代
          view: data.screen_size, //图像宽高尺寸
        };
        that.session_id = data.session_id;
        that.ViewMode = data.direction == 1 ? "heng" : "shu";
        that.argsdata = {
          Level: data.scene,
          SpawnHuman: data.anchorIdentity,
          ViewMode: that.ViewMode,
        };

        //$(talkbox).html(replace(data.welcome.text, "\n\n", "\n"));
        that.welcome = data.welcome;
        that.duration = data.welcome.duration;
        that.#btnInit();
        loaded++;
        if (loaded == 2) {
          that.loadOver();
        }
        //that.#addtext(data.welcome.text,'left','http://www.xttblog.com/wp-content/uploads/2016/03/123.png');
      },
      err: function (res) {
        // that.callback({ state: "ERROR", msg: "获取配置信息失败", info: res });
        that.state = "ERROR";
        alertText("获取配置信息失败");
        that.#hidden();
      },
    });
  }

  #addtext(text, direction) {
    let imgClassName = direction === "left" ? "imgleft" : "imgright";
    let spanClassName = direction === "left" ? "spanleft" : "spanright";
    let img = direction === "left" ? "/baize/bz.png" : "/baize/user.png";
    let baize_think = document.querySelectorAll(".baize_think");
    if (baize_think.length) {
      baize_think.forEach((item) => {
        content.removeChild(item);
      });
    }
    content.innerHTML +=
      '<li><img src="' +
      img +
      '" style="width: 40px;height: 40px;border-radius: 50%" class="' +
      imgClassName +
      '"><div class="' +
      spanClassName +
      '">' +
      text +
      "</div></li>";
    sendTextInput.value = "";
    sendTextInput.style.height = "40px";
    // 内容过多时,将滚动条放置到最底端
    content.scrollTop = content.scrollTop + 100000000000000;
  }
  wekeUp() {
    metabtns.style.display = "none";
    // loading.style.display = "block";
    // loading.style.display = "none";
    Metabtn.style.display = "none";
    closeBtn.style.display = "block";
    closeBtns.style.display = "block";
    this.#show();
  }
  hibernate() {
    let that = this
    content.innerHTML = "";
    if (that.beforeclose) return alertText("正在关闭中...");
    // that.callback({ state: "BEFORECLOSE", msg: "正在关闭中" });
    that.state = "BEFORECLOSE";
    that.#closeVoice();
    that.#hidden();
    that.beforeclose = false;
    
    if (unityInstanceV) {
      unityInstanceV.SendMessage(
        "WebManager",
        "WebToUnity",
        JSON.stringify({
          OpCode: 1003, //事件类型,unity需要
        })
      );
    }
  }
  //按钮注册事件
  #btnInit() {
    let that = this;
    closeBtn.addEventListener("click", () => {
      this.hibernate()
    });
    closeBtns.addEventListener("click", () => {
      this.hibernate()
    });
    Metabtn.addEventListener("click", () => {
      this.wekeUp()
    });
    voicebtn.addEventListener("click", () => {
      that.locaConfig.voice = that.$vue.voice;
      that.setConfig();
      if (that.locaConfig.voice && MetaWaReady) {
        that.#getalisession();
      } else {
        that.#closeVoice();
      }
    });
    // 文本输入框的点击事件
    sendTextBtn.addEventListener("click", () => {
      let message = that.$vue.message;
      if (message.trim() == "") {
        return alertText("请输入问题");
      } else {
        that.#addtext(message, "right");
        that.talking = false;
        this.talk(message, false);
        that.callback({ state: "INPUT", msg: message });
      }
    });
    // 文本输入框的enter事件
    sendTextInput.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        let message = that.$vue.message;
        if (message.trim() == "") {
          return alertText("请输入问题");
        } else {
          that.#addtext(message, "right");
          that.talking = false;
          this.talk(message, false);
          that.callback({ state: "INPUT", msg: message });
        }
      }
    });
    // 文本输入框的focus事件
    sendTextInput.addEventListener("focus", (event) => {
      that.$vue.voice = false;
      that.locaConfig.voice = that.$vue.voice;
      that.setConfig();
      that.#closeVoice();
    });
    //静音
    soundBtn.addEventListener("click", () => {
      this.locaConfig.sound = that.$vue.sound;
      this.setConfig();
    });
  }

  #show() {
    let that = this;
    threeDWebGl.style.display = "block";
    closeBtn.style.display = "block";
    closeBtns.style.display = "block";
    Metabtn.style.display = "none";
    document.getElementById("metaContainer").style.height = "100%";
    document.getElementById("metaContainer").style.width = "100%";
    // that.threeD.setAction('1');
    // setTimeout(()=>{
    metatalkbox.style.display = "block";
    that.talking = true;
    that.preview(that.welcome, 2);
    // if (that.locaConfig.voice) that.#getalisession();
    MetaWaReady = true;
    // },4000)
  }
  #hidden() {
    let that = this;
    metatalkbox.style.display = "none";
    document.getElementById("metaContainer").style.height = "0px";
    document.getElementById("metaContainer").style.width = "0px";
    that.#closeVoice();
    MetaWaReady = false;
    that.talking = false;
    // that.callback({ state: "CLOSED", msg: "已关闭" });
    that.state = "CLOSED";
    closeBtn.style.display = "none";
    closeBtns.style.display = "none";
    // setTimeout(()=>{
    threeDWebGl.style.display = "none";
    closeBtn.style.display = "none";
    closeBtns.style.display = "none";
    Metabtn.style.display = "block";
    metabtns.style.display = "block";
    this.$vue.userTalk = false;
    // },8000)
  }
  //驱动虚拟人
  preview(res, type = 1) {
    let that = this;
    $(talkbox).html(replace(res.text, "\n\n", "\n"));
    this.#addtext(res.text, "left");
    if (!this.locaConfig.sound || !this.tabStatus) {
      this.talking = false;
      if (that.locaConfig.voice) that.#getalisession();
      return;
    }
    if (unityInstanceV) {
      unityInstanceV.SendMessage(
        "WebManager",
        "WebToUnity",
        JSON.stringify({
          OpCode: 1002, //事件类型,unity需要
          AnimationId: type, //AnimationId 有3个，int型。 0待机、1播报动作、2打招呼
          TalkId: res.Talk, //定义唯一值,播放结束/开始 会回传 //string类型
          Content: res.Talk,
        })
      );
    }
  }
  saypreview(res, type) {
    let that = this;
    if (!this.locaConfig.sound || !this.tabStatus) {
      this.talking = false;
      if (that.locaConfig.voice) that.#getalisession();
      return;
    }
    if (unityInstanceV) {
      unityInstanceV.SendMessage(
        "WebManager",
        "WebToUnity",
        JSON.stringify({
          OpCode: 1002, //事件类型,unity需要
          AnimationId: 1, //AnimationId 有3个，int型。 0待机、1播报动作、2打招呼
          TalkId: res.Talk, //定义唯一值,播放结束/开始 会回传 //string类型
          Content: res.Talk,
        })
      );
    }
  }
  talk(params, sdk = false) {
    if (this.talking || !MetaWaReady) return;
    let that = this;
    if (recorder) {
      try {
        recorder.stop();
        recorder = null;
      } catch (error) {}
    }
    if (aliTimer) clearInterval(aliTimer);
    if (stWeb) {
      try {
        stWeb.close();
        stWeb = null;
      } catch (error) {}
    }
    if (sdk) {
      that.#addtext(params, "right");
    }
    that.talking = true;
    that.$vue.message = "";
    content.innerHTML += baize_think;
    content.scrollTop = content.clientHeight + 100000000000000;
    $.ajax({
      method: "get",
      url: that.host + "/api/wisdom/service/ask",
      // dataType: 'jsonp',
      data: {
        question: params,
        flag: that.flag,
        session_id: that.session_id,
      },
      success: function (res) {
        changeData = "";
        searchData = "";
        // that.callback({
        //   state: "TALK",
        //   msg: "获取问题及答案",
        //   question: params,
        //   answer: res.data.answer.text,
        // });
        switch (res.data.answer.text) {
          case that.timeOut.text:
            that.getList(params);
            return false;
          case that.noReturn.text:
            that.getList(params);
            return false;
          default:
            that.duration = res.data.answer.duration;
            that.preview(res.data.answer);
        }
        that.state = "TALK";
      },
    });
  }

  getList(text) {
    let that = this;
    let num = 0;
    that.reference = 0;
    that.reference_txt = "";
    that.say_text = "";
    that.wav = true;

    let url = `https://web-le.ouc-online.com.cn/api/SageGPT/Question/Proxy?Question=${text}`;
    const evenetSource = new EventSource(url);
    // document.getElementById('sendTextInputSdk').attributes['disabled'] = true;
    let box = null;
    evenetSource.onmessage = function (event) {
      let baize_think = document.querySelectorAll(".baize_think");
      if (baize_think.length) {
        baize_think.forEach((item) => {
          content.removeChild(item);
        });
      }
      const eventData = event.data;
      if (eventData.indexOf("finish") > -1) {
        //document.getElementById('sendTextInputSdk').attributes['disabled'] = false;
        evenetSource.close();
        if (that.reference_txt !== "") {
          box.innerHTML +=
            '<h2 style="margin: 20px 0px 0px 0px;font-size: 16px">引用来源:</h2>' +
            that.reference_txt;
        } else {
          that.send_say(that.say_text);
        }
      } else {
        let obj = JSON.parse(eventData);
        let res = that.getTxt(obj);
        if (res) {
          if (num === 0) {
            content.innerHTML +=
              '<li><img src="/baize/bz.png" class="imgleft"><div class="spanleft"></div></li>';
            box = document
              .getElementById("contentSdk")
              .lastElementChild.getElementsByTagName("div")[0];
          }
          num++;
          box.innerHTML += res;
        }
      }
      content.scrollTop = content.clientHeight + 100000000000000;
    };
  }

  getTxt(obj) {
    let that = this;
    switch (obj.type) {
      case "collection":
        return false;
      case "text":
        if (that.wav) {
          that.say_text += obj.data.text.replace("\n", "");
        }
        return obj.data.text.replace("\n", "</br>");
      case "reference":
        that.wav = false;
        let res = obj.data.snippet;
        let arr = res.content.split("```");
        if (that.reference === 0) {
          if (that.say_text !== "") {
            this.send_say(that.say_text);
          } else {
          }
        } else {
          if (that.say_text == "") {
            this.send_say(that.say_text);
          }
        }

        that.reference++;
        let txt =
          '<p><span style="color: #4b78f2">[' +
          that.reference +
          "]</span>" +
          res.title +
          ' <a href="' +
          arr[1] +
          '" target="_blank" style="color: #4b78f2">点击查看视频</a></p><p>' +
          arr[2]?.replace("\n", "</br>") +
          "</p>";
        that.reference_txt += txt;

        return '<span style="color: #4b78f2">[' + that.reference + "]</span>";
      /*return '<h2 style="margin: 20px 0px 0px 0px;font-size: 16px">引用来源:</h2>' +
            '<p><span style="color: #4b78f2">[1]</span>'+res.title+' <a href="'+arr[1]+'" target="_blank" style="color: #4b78f2">点击查看视频</a></p><p>'+arr[2].replace('\n','</br>')+'</p>';*/
      case "cite":
        return false;
      default:
        return false;
    }
  }

  //直接读文字
  say(item, sdk = false) {
    let that = this;
    $.ajax({
      method: "post",
      url: that.host + "/api/wisdom/service/send-wav",
      // dataType: 'jsonp',
      data: {
        text: item,
        flag: that.flag,
      },
      success: function (res) {
        if (res.code == 10000) {
          that.preview({ ...res.data, text: item });
        } else {
          that.talking = false;
          if (that.locaConfig.voice) that.#getalisession();
        }
      },
    });
  }
  send_say(item, sdk = false) {
    let that = this;
    if (!this.locaConfig.sound) {
      this.talking = false;
      return;
    }
    $.ajax({
      method: "post",
      url: that.host + "/api/wisdom/service/send-wav",
      // dataType: 'jsonp',
      data: {
        text: item,
        flag: that.flag,
      },
      success: function (res) {
        if (res.code == 10000) {
          that.saypreview({ ...res.data, text: item });
        } else {
          that.talking = false;
          if (that.locaConfig.voice) that.#getalisession();
        }
      },
    });
  }

  //回调
  callback(params) {}
  //阿里语音识别session码
  #getalisession() {
    let that = this;
    this.#closeVoice();
    $.ajax({
      method: "get",
      url: that.host + "/api/wisdom/service/iat-ws-url",
      success: function (res) {
        that.aliurl = res.data.url;
        that.appKey = res.data.appKey;
        if (that.tabStatus) that.#aliWebsoket(that.aliurl, that.appKey);
      },
    });
  }
  //阿里语音转文字
  #aliWebsoket(url, appKey) {
    let that = this;
    if (!that.locaConfig.voice) return;
    let reg =
      /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/;

    let st = new SpeechTranscription({
      url: url,
      appkey: appKey,
    });

    st.on("started", (msg) => {
      //console.log("Client recv started");
      // this.stResult = msg;
    });
    st.on("changed", (msg) => {
      this.$vue.userTalk = true;
      // if (this.talking || !MetaWaReady) return;
      // let message = JSON.parse(msg);
      // let result = message.payload.result;
      // if (
      //   changeData != "" &&
      //   !reg.test(changeData.substr(changeData.length - 1))
      // ) {
      //   searchData = changeData + "，" + result;
      // } else {
      //   searchData = changeData + result;
      // }
      //$(talkbox).html(replace(searchData, "\n\n", "\n"));
      // this.#addtext(searchData,'right','http://www.xttblog.com/wp-content/uploads/2016/03/123.png')
      // talkbox.innerHtml = replace(searchData, "\n\n", "\n");
    });
    st.on("completed", (msg) => {
      // //console.log("Client recv completed:", msg);
    });

    st.on("begin", (msg) => {
      this.$vue.userTalk = true;
    });

    st.on(
      "end",
      debounce((msg) => {
        if (this.talking || !MetaWaReady) return;
        let message = JSON.parse(msg);
        let result = message.payload.result;
        // if (result.substr(result.length - 1) == "。") {
        //   result = result.substring(0, result.length - 1);
        // }
        // if (
        //   changeData != "" &&
        //   !reg.test(changeData.substr(changeData.length - 1))
        // ) {
        //   result = "，" + result;
        // }
        changeData = result;
        searchData = changeData;
        // let http = httpArr.find((i) => searchData.includes("打开" + i.name));
        // if (http) {
        //   const eleLink = document.createElement("a");
        //   eleLink.href = http.val;
        //   eleLink.target = "_blank";
        //   eleLink.style.display = "none";
        //   document.body.appendChild(eleLink);
        //   eleLink.click();
        //   document.body.removeChild(eleLink);
        //   changeData = "";
        //   searchData = "";
        //   return;
        // }
        this.callback(msg);
        // this.talk(searchData);
        //$(talkbox).html(replace(searchData, "\n\n", "\n"));
        this.#addtext(searchData, "right");
        this.$vue.userTalk = false;
        this.talk(searchData, false);
        // talkbox.innerHtml = replace(searchData, "\n\n", "\n");
      })
    );

    st.on("closed", () => {});

    st.on("failed", (msg) => {
      that.callback({
        state: "ERROR",
        msg: "语音转文字异常",
        info: msg,
      });
      that.state = "ERROR";
      //  alertText("语音转文字异常");
    });
    stWeb = st;
    stWeb && stWeb.start
      ? start()
      : setTimeout(() => {
          start();
        }, 500);
    function start() {
      if (that.tabStatus) {
        stWeb.start(stWeb.defaultStartParams()).then(() => {
          that.#aliInit();
        });
      }
    }
  }
  //录音权限
  #aliInit() {
    clearInterval(aliTimer);
    aliTimer = null;
    let that = this;
    recorder = new Recorder({
      sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
      sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
      numChannels: 1, // 声道，支持 1 或 2， 默认是1
      compiling: true, // 是否边录边转换，默认是false
    });
    recorder && recorder.getPermission && recorder.start
      ? getRecorder()
      : setTimeout(() => {
          getRecorder();
        }, 1000);
    function getRecorder() {
      recorder.getPermission().then(
        () => {
          recorder.start().then(() => {
            // that.callback({
            //   state: "RECORDER",
            //   msg: "收音中",
            // });
            that.state = "RECORDER";
            aliTimer = setInterval(() => {
              if (stWeb && stWeb.sendAudio && recorder && recorder.getPCMBlob) {
                stWeb.sendAudio(recorder.getPCMBlob());
              }
            }, 250);
          });
        },
        (error) => {
          that.callback({
            state: "ERROR",
            msg: "收音异常",
            recorder: `${error.name} : ${error.message}`,
          });
          that.state = "ERROR";
          alertText("收音异常");
        }
      );
    }
  }

  //关闭收音
  #closeVoice() {
    let that = this;
    this.$vue.userTalk = false;
    if (recorder) {
      try {
        recorder.stop();
        recorder = null;
      } catch (error) {}
    }
    if (stWeb) {
      try {
        stWeb.close();
        stWeb = null;
      } catch (error) {}
    }
    if (audio) {
      try {
        audio.pause();
        audio = null;
      } catch (error) {}
    }
    if (soundBgm) {
      try {
        soundBgm.pause();
        soundBgm = null;
      } catch (error) {}
    }
    // that.callback({ state: "MUTE", msg: "已关闭收音" });
    that.state = "MUTE";
  }
}
export default virtual;
